import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import PrivateRoute from './components/auth/PrivateRoute';
import Navbar from './components/layout/Navbar';
import Dashboard from './components/dashboard/Dashboard';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Reports from './components/reports/Reports';
import CreateOrganization from './components/organization/CreateOrganization';
import CreateTicket from './components/tickets/CreateTicket';
import TicketList from './components/tickets/TicketList';
import CreateClient from './components/clients/CreateClient';
import ClientList from './components/clients/ClientList';
import ClientDetail from './components/clients/ClientDetail';
import OrganizationSettings from './components/organization/OrganizationSettings';
import UserProfile from './components/profile/UserProfile';
import TicketView from './components/tickets/TicketView';
import SystemAdminDashboard from './components/admin/SystemAdminDashboard';
import { initializeSystemSettings } from './firebase/collections/systemSettings';
import { RequireAuth } from './components/auth/RequireAuth';

function App() {
  useEffect(() => {
    initializeSystemSettings().catch(console.error);
  }, []);

  return (
    <AuthProvider>
      <div className="min-h-screen bg-gray-100">
        <Navbar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/organization/:orgId/reports" element={<Reports />} />
          <Route
            path="/organization/new"
            element={
              <PrivateRoute>
                <CreateOrganization />
              </PrivateRoute>
            }
          />
          <Route
            path="/organization/:orgId/tickets"
            element={
              <PrivateRoute>
                <TicketList />
              </PrivateRoute>
            }
          />
          <Route
            path="/organization/:orgId/tickets/new"
            element={
              <PrivateRoute>
                <CreateTicket />
              </PrivateRoute>
            }
          />
          <Route
            path="/organization/:orgId/clients/new"
            element={
              <PrivateRoute>
                <CreateClient />
              </PrivateRoute>
            }
          />
          <Route
            path="/organization/:orgId/clients"
            element={
              <PrivateRoute>
                <ClientList />
              </PrivateRoute>
            }
          />
          <Route
            path="/organization/:orgId/clients/:clientId"
            element={
              <PrivateRoute>
                <ClientDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/organization/:orgId/settings"
            element={
              <PrivateRoute>
                <OrganizationSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <UserProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/organization/:orgId/tickets/:ticketId"
            element={
              <PrivateRoute>
                <TicketView />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/system"
            element={
              <PrivateRoute>
                <SystemAdminDashboard />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route 
            path="/admin/dashboard" 
            element={
              <RequireAuth>
                <SystemAdminDashboard />
              </RequireAuth>
            } 
          />
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
