import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { format, startOfMonth, endOfMonth, subMonths } from 'date-fns';
import { hu } from 'date-fns/locale';
import { PDFDownloadLink } from '@react-pdf/renderer';
import MonthlyReportPDF from './MonthlyReportPDF';
import LoadingScreen from '../ui/LoadingScreen';

export default function Reports() {
  const { orgId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(() => new Date());
  const [organization, setOrganization] = useState(null);
  const [reportData, setReportData] = useState({
    tickets: [],
    clientDetails: {},
    summary: {
      totalWorkTime: 0,
      totalTickets: 0,
      totalClients: 0,
      averageTimePerTicket: 0
    }
  });

  // Szervezet adatainak betöltése
  useEffect(() => {
    async function fetchOrganization() {
      const orgDoc = await getDoc(doc(db, 'organizations', orgId));
      if (orgDoc.exists()) {
        setOrganization(orgDoc.data());
      }
    }
    fetchOrganization();
  }, [orgId]);

  useEffect(() => {
    async function fetchReportData() {
      try {
        setLoading(true);
        const startDate = startOfMonth(selectedMonth);
        const endDate = endOfMonth(selectedMonth);

        // Ticketek lekérése
        const ticketsQuery = query(
          collection(db, 'tickets'),
          where('organizationId', '==', orgId),
          where('createdAt', '>=', startDate),
          where('createdAt', '<=', endDate)
        );

        const ticketsSnapshot = await getDocs(ticketsQuery);
        const tickets = ticketsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        // Ügyfelenkénti adatok feldolgozása
        const clientDetails = {};
        let totalWorkTime = 0;

        for (const ticket of tickets) {
          const clientId = ticket.clientId;
          if (!clientDetails[clientId]) {
            clientDetails[clientId] = {
              name: ticket.clientName,
              totalWorkTime: 0,
              tickets: [],
              workTimeEntries: []
            };
          }

          // Munkaidő bejegyzések feldolgozása
          let ticketTotalTime = 0;
          ticket.workTimes?.forEach(entry => {
            const minutes = (entry.hours * 60) + (entry.minutes || 0);
            ticketTotalTime += minutes;
            totalWorkTime += minutes;

            clientDetails[clientId].workTimeEntries.push({
              date: entry.timestamp,
              ticketTitle: ticket.title,
              description: entry.description,
              time: minutes,
              ticketId: ticket.id
            });
          });

          clientDetails[clientId].tickets.push({
            id: ticket.id,
            title: ticket.title,
            status: ticket.status,
            priority: ticket.priority,
            totalTime: ticketTotalTime,
            createdAt: ticket.createdAt,
            deadline: ticket.deadline
          });

          clientDetails[clientId].totalWorkTime += ticketTotalTime;
        }

        setReportData({
          tickets,
          clientDetails,
          summary: {
            totalWorkTime,
            totalTickets: tickets.length,
            totalClients: Object.keys(clientDetails).length,
            averageTimePerTicket: tickets.length ? Math.round(totalWorkTime / tickets.length) : 0
          }
        });

      } catch (error) {
        console.error('Error fetching report data:', error);
        setError('Hiba történt az adatok betöltésekor');
      } finally {
        setLoading(false);
      }
    }

    if (orgId) {
      fetchReportData();
    }
  }, [orgId, selectedMonth]);

  function formatWorkTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours} óra ${mins} perc`;
  }

  if (loading) return <LoadingScreen />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Fejléc és vezérlők */}
      <div className="md:flex md:items-center md:justify-between mb-6">
        <div>
          <h2 className="text-2xl font-bold text-gray-900">
            {organization?.name} - Havi kimutatás
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            {format(selectedMonth, 'yyyy. MMMM', { locale: hu })}
          </p>
        </div>
        
        <div className="mt-4 md:mt-0 flex space-x-3">
          <select
            value={format(selectedMonth, 'yyyy-MM')}
            onChange={(e) => {
              const [year, month] = e.target.value.split('-');
              setSelectedMonth(new Date(parseInt(year), parseInt(month) - 1));
            }}
            className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            {[...Array(12)].map((_, i) => {
              const date = subMonths(new Date(), i);
              return (
                <option key={i} value={format(date, 'yyyy-MM')}>
                  {format(date, 'yyyy. MMMM', { locale: hu })}
                </option>
              );
            })}
          </select>

          <PDFDownloadLink
            document={
              <MonthlyReportPDF 
                reportData={loading ? null : reportData} 
                month={selectedMonth}
                organization={organization}
              />
            }
            fileName={`${organization?.name || 'riport'}-${format(selectedMonth, 'yyyy-MM')}.pdf`}
            className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 
              ${loading ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'}`}
          >
            {({ loading: pdfLoading }) => (pdfLoading ? 'Generálás...' : 'PDF Letöltése')}
          </PDFDownloadLink>
        </div>
      </div>

      {/* Összesítő kártyák */}
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <dt className="text-sm font-medium text-gray-500 truncate">
              Összes munkaidő
            </dt>
            <dd className="mt-1 text-3xl font-semibold text-gray-900">
              {formatWorkTime(reportData.summary.totalWorkTime)}
            </dd>
          </div>
        </div>
        
        {/* További összesítő kártyák... */}
      </div>

      {/* Ügyfelenkénti részletes lista */}
      {Object.entries(reportData.clientDetails).map(([clientId, client]) => (
        <div key={clientId} className="bg-white shadow rounded-lg mb-8">
          <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
            <h3 className="text-lg font-medium text-gray-900">
              {client.name}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Összes munkaidő: {formatWorkTime(client.totalWorkTime)}
            </p>
          </div>
          
          <div className="px-4 py-5 sm:p-6">
            <div className="flow-root">
              <ul className="-mb-8">
                {client.workTimeEntries
                  .sort((a, b) => b.date - a.date)
                  .map((entry, entryIdx) => (
                    <li key={entryIdx}>
                      <div className="relative pb-8">
                        {entryIdx !== client.workTimeEntries.length - 1 && (
                          <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" />
                        )}
                        <div className="relative flex space-x-3">
                          <div>
                            <span className="h-8 w-8 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white">
                              <svg className="h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                              </svg>
                            </span>
                          </div>
                          <div className="min-w-0 flex-1">
                            <div>
                              <div className="text-sm">
                                <a href={`/tickets/${entry.ticketId}`} className="font-medium text-gray-900">
                                  {entry.ticketTitle}
                                </a>
                              </div>
                              <p className="mt-0.5 text-sm text-gray-500">
                                {entry.description}
                              </p>
                            </div>
                            <div className="mt-2 text-sm text-gray-500">
                              <span className="font-medium text-gray-900">
                                {formatWorkTime(entry.time)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
} 