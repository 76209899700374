import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';

export default function OrganizationList({ onSelectOrg }) {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    async function fetchOrganizations() {
      try {
        const q = query(
          collection(db, 'organizations'),
          where('members', 'array-contains', currentUser.uid)
        );

        const querySnapshot = await getDocs(q);
        const orgList = [];
        querySnapshot.forEach((doc) => {
          orgList.push({ id: doc.id, ...doc.data() });
        });

        setOrganizations(orgList);
      } catch (error) {
        console.error('Error fetching organizations:', error);
        setError('Hiba történt a szervezetek betöltésekor: ' + error.message);
      } finally {
        setLoading(false);
      }
    }

    if (currentUser) {
      fetchOrganizations();
    }
  }, [currentUser]);

  if (loading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {organizations.map((org) => (
          <li 
            key={org.id}
            onClick={() => onSelectOrg(org.id)}
            className="cursor-pointer"
          >
            <div className="px-4 py-4 sm:px-6 hover:bg-gray-50">
              <div className="flex items-center justify-between">
                <div className="flex-1 min-w-0">
                  <Link
                    to={`/organization/${org.id}`}
                    className="text-sm font-medium text-indigo-600 hover:text-indigo-900 truncate"
                  >
                    {org.name}
                  </Link>
                  <p className="text-sm text-gray-500 truncate">{org.description}</p>
                </div>
                <div className="flex items-center space-x-4">
                  {/* Statisztikák */}
                  <div className="flex items-center space-x-2">
                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium bg-green-100 text-green-800 rounded-full">
                      {org.clients?.length || 0} ügyfél
                    </span>
                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 rounded-full">
                      {org.members?.length || 0} tag
                    </span>
                  </div>
                  
                  {/* Műveletek */}
                  <div className="flex items-center space-x-2">
                    <Link
                      to={`/organization/${org.id}/tickets`}
                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Ticketek
                    </Link>
                    <Link
                      to={`/organization/${org.id}/clients`}
                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Ügyfelek
                    </Link>
                    <Link
                      to={`/organization/${org.id}/settings`}
                      className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Beállítások
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mt-2 sm:flex sm:justify-between">
                <div className="sm:flex">
                  <p className="flex items-center text-sm text-gray-500">
                    <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                    </svg>
                    {org.email}
                  </p>
                  {org.phone && (
                    <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                      <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                      </svg>
                      {org.phone}
                    </p>
                  )}
                </div>
                <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                  <svg className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                  </svg>
                  Létrehozva: {new Date(org.createdAt).toLocaleDateString()}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {organizations.length === 0 && (
        <div className="text-center py-8">
          <p className="text-sm text-gray-500">Még nincs szervezeted.</p>
          <Link
            to="/organization/new"
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Új szervezet létrehozása
          </Link>
        </div>
      )}
    </div>
  );
}