import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import OrganizationList from '../organization/OrganizationList';
import LoadingScreen from '../ui/LoadingScreen';
import WebForYouLogo from '../common/WebForYouLogo';
import InitializeAdmin from '../admin/InitializeAdmin';
import { isSystemAdmin } from '../../firebase/collections/systemSettings';

export default function Dashboard() {
  const { currentUser } = useAuth();
  const [stats, setStats] = useState({
    totalOrganizations: 0,
    totalClients: 0,
    totalTickets: 0,
    openTickets: 0
  });
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    async function fetchStats() {
      if (!currentUser) return;
      
      try {
        console.log('Fetching stats for user:', currentUser.uid);
        
        // Szervezetek lekérése
        const orgQuery = query(
          collection(db, 'organizations'),
          where('members', 'array-contains', currentUser.uid)
        );
        const orgSnapshot = await getDocs(orgQuery);
        console.log('Found organizations:', orgSnapshot.size);
        
        const orgIds = orgSnapshot.docs.map(doc => doc.id);

        // Ha nincs szervezet, ne futtassunk további lekérdezéseket
        if (orgIds.length === 0) {
          console.log('No organizations found');
          setStats({
            totalOrganizations: 0,
            totalClients: 0,
            totalTickets: 0,
            openTickets: 0
          });
          setLoading(false);
          return;
        }

        // Ügyfelek számolása a szervezetek dokumentumaiból
        let totalClients = 0;
        orgSnapshot.docs.forEach(doc => {
          const orgData = doc.data();
          totalClients += (orgData.clients || []).length;
        });

        // Ticketek lekérése szervezetenként
        let totalTickets = 0;
        let openTickets = 0;

        for (const orgId of orgIds) {
          console.log('Fetching data for organization:', orgId);
          
          // Ticketek számolása
          const ticketQuery = query(
            collection(db, 'tickets'),
            where('organizationId', '==', orgId)
          );
          const ticketSnapshot = await getDocs(ticketQuery);
          console.log('Found tickets:', ticketSnapshot.size);
          
          totalTickets += ticketSnapshot.size;
          openTickets += ticketSnapshot.docs.filter(doc => 
            ['new', 'in_progress'].includes(doc.data().status)
          ).length;
        }

        console.log('Setting stats:', {
          totalOrganizations: orgSnapshot.size,
          totalClients,
          totalTickets,
          openTickets
        });

        setStats({
          totalOrganizations: orgSnapshot.size,
          totalClients,
          totalTickets,
          openTickets
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
        setError('Hiba történt a statisztikák betöltésekor');
      } finally {
        setLoading(false);
      }
    }

    fetchStats();
  }, [currentUser]);

  useEffect(() => {
    async function checkAdminStatus() {
      if (currentUser) {
        const adminStatus = await isSystemAdmin(currentUser.uid);
        setIsAdmin(adminStatus);
      }
    }
    checkAdminStatus();
  }, [currentUser]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="md:flex md:items-center md:justify-between mb-6">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Dashboard
          </h2>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4 space-x-3">
          <Link
            to="/organization/new"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Új szervezet
          </Link>
          {isAdmin && (
            <Link
              to="/admin/dashboard"
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
            >
              Rendszeradmin
            </Link>
          )}
        </div>
      </div>

      {/* Statisztikák */}
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8">
        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg className="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"/>
                </svg>
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Szervezetek
                  </dt>
                  <dd className="text-lg font-medium text-gray-900">
                    {stats.totalOrganizations}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg className="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"/>
                </svg>
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Ügyfelek
                  </dt>
                  <dd className="text-lg font-medium text-gray-900">
                    {stats.totalClients}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg className="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"/>
                </svg>
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Összes ticket
                  </dt>
                  <dd className="text-lg font-medium text-gray-900">
                    {stats.totalTickets}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white overflow-hidden shadow rounded-lg">
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <svg className="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Nyitott ticketek
                  </dt>
                  <dd className="text-lg font-medium text-gray-900">
                    {stats.openTickets}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Szervezetek listája */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-8">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Szervezetek
          </h3>
     
        </div>
        <OrganizationList onSelectOrg={setSelectedOrg} />
      </div>

      {/* Gyors műveletek */}
      {selectedOrg && (
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Gyors műveletek
            </h3>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5">
              <Link
                to={`/organization/${selectedOrg}/tickets/new`}
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Új ticket
              </Link>
              <Link
                to={`/organization/${selectedOrg}/clients/new`}
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Új ügyfél
              </Link>
              <Link
                to={`/organization/${selectedOrg}/tickets`}
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Ticketek kezelése
              </Link>
              <Link
                to={`/organization/${selectedOrg}/clients`}
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Ügyfelek kezelése
              </Link>
              <Link
                to={`/organization/${selectedOrg}/reports`}
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Kimutatások
              </Link>
            </div>
          </div>
        </div>
      )}

      <WebForYouLogo />

      {currentUser && currentUser.uid === 'FaIRu45Ni7MgU6CAw6Q69srOQ9w2' && (
        <InitializeAdmin />
      )}
    </div>
  );
} 