import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import { doc, getDoc, updateDoc, serverTimestamp, arrayUnion } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
import LoadingScreen from '../ui/LoadingScreen';

// Dátum formázó függvény
function formatDate(timestamp) {
  if (!timestamp) return 'Nincs dátum';
  
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  
  try {
    const date = timestamp.seconds ? 
      new Date(timestamp.seconds * 1000) : 
      new Date(timestamp);
      
    return date.toLocaleDateString('hu-HU', options);
  } catch (error) {
    console.error('Dátum formázási hiba:', error);
    return 'Érvénytelen dátum';
  }
}

function formatWorkTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours} óra ${mins} perc`;
}

export default function TicketView() {
  const { orgId, ticketId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [updating, setUpdating] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({
    title: '',
    description: '',
    deadline: '',
    priority: ''
  });
  const [comment, setComment] = useState('');
  const [isSubmittingComment, setIsSubmittingComment] = useState(false);
  const [workTime, setWorkTime] = useState({
    description: '',
    hours: '',
    minutes: '',
    isSubmitting: false
  });
  const [editingWorkTime, setEditingWorkTime] = useState(null);
  const [deletingWorkTime, setDeletingWorkTime] = useState(null);
  const [editingComment, setEditingComment] = useState(null);
  const [deletingComment, setDeletingComment] = useState(null);
  
  // Referencia a dropdown menühöz
  const menuRef = useRef(null);

  // Kattintás figyelése a dokumentumon a menü bezárásához
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    async function fetchTicket() {
      try {
        const ticketDoc = await getDoc(doc(db, 'tickets', ticketId));
        if (ticketDoc.exists()) {
          const data = ticketDoc.data();
          // Konvertáljuk a timestamp-et Date objektummá
          const createdAt = data.createdAt?.seconds ? 
            new Date(data.createdAt.seconds * 1000) : 
            new Date(data.createdAt);
            
          const updatedAt = data.updatedAt?.seconds ? 
            new Date(data.updatedAt.seconds * 1000) : 
            data.updatedAt ? new Date(data.updatedAt) : null;

          setTicket({
            id: ticketDoc.id,
            ...data,
            createdAt,
            updatedAt
          });
        } else {
          setError('Ticket nem található');
        }
      } catch (err) {
        setError('Hiba történt az adatok betöltésekor');
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    fetchTicket();
  }, [ticketId]);

  // Form inicializálása amikor betöltődik a ticket
  useEffect(() => {
    if (ticket) {
      setEditForm({
        title: ticket.title,
        description: ticket.description,
        deadline: ticket.deadline ? new Date(ticket.deadline).toISOString().slice(0, 16) : '',
        priority: ticket.priority
      });
    }
  }, [ticket]);

  const handleStatusChange = async (newStatus) => {
    if (!ticket || updating) return;
    
    setIsMenuOpen(false); // Bezárjuk a menüt a kiválasztás után
    setUpdating(true);
    
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);
      
      const historyEntry = {
        action: 'status_changed',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: `Státusz módosítva: ${getStatusText(ticket.status)} → ${getStatusText(newStatus)}`
      };

      const updates = {
        status: newStatus,
        updatedAt: serverTimestamp(),
        updatedBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        }
      };

      await updateDoc(ticketRef, {
        ...updates,
        history: arrayUnion(historyEntry)
      });

      setTicket(prev => ({
        ...prev,
        ...updates,
        status: newStatus,
        history: [...(prev.history || []), historyEntry]
      }));

    } catch (err) {
      console.error('Státusz módosítási hiba:', err);
      setError('Hiba történt a státusz módosításakor: ' + err.message);
    } finally {
      setUpdating(false);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setUpdating(true);
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);

      const historyEntry = {
        action: 'edited',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: 'Ticket módosítva'
      };

      const updates = {
        title: editForm.title,
        description: editForm.description,
        deadline: editForm.deadline ? new Date(editForm.deadline).toISOString() : null,
        updatedAt: serverTimestamp(),
        updatedBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        }
      };

      await updateDoc(ticketRef, {
        ...updates,
        history: arrayUnion(historyEntry)
      });

      // Frissítjük a lokális state-et
      setTicket(prev => ({
        ...prev,
        ...updates,
        history: [...(prev.history || []), historyEntry]
      }));

      setIsEditing(false);
    } catch (err) {
      console.error('Szerkesztési hiba:', err);
      setError('Hiba történt a módosítás során: ' + err.message);
    } finally {
      setUpdating(false);
    }
  };

  const handleAddComment = async (e) => {
    e.preventDefault();
    if (!comment.trim()) return;

    setIsSubmittingComment(true);
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);

      const commentData = {
        content: comment.trim(),
        createdAt: now.toISOString(),
        createdBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        }
      };

      const historyEntry = {
        action: 'comment_added',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: 'Új hozzászólás'
      };

      await updateDoc(ticketRef, {
        comments: arrayUnion(commentData),
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      // Frissítjük a lokális state-et
      setTicket(prev => ({
        ...prev,
        comments: [...(prev.comments || []), commentData],
        history: [...(prev.history || []), historyEntry]
      }));

      setComment(''); // Form törlése
    } catch (err) {
      console.error('Komment hozzáadási hiba:', err);
      setError('Hiba történt a komment hozzáadásakor: ' + err.message);
    } finally {
      setIsSubmittingComment(false);
    }
  };

  const handleAddWorkTime = async (e) => {
    e.preventDefault();
    if (!workTime.description.trim() || (workTime.hours === '' && workTime.minutes === '')) return;

    setWorkTime(prev => ({ ...prev, isSubmitting: true }));
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);

      // Átváltás percekre
      const totalMinutes = (parseInt(workTime.hours || 0) * 60) + parseInt(workTime.minutes || 0);
      
      if (totalMinutes === 0) {
        throw new Error('Kérjük adjon meg munkaidőt');
      }

      const workTimeEntry = {
        description: workTime.description.trim(),
        minutes: totalMinutes,
        createdAt: now.toISOString(),
        createdBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        }
      };

      const historyEntry = {
        action: 'worktime_added',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: `Munkaidő hozzáadva: ${formatWorkTime(totalMinutes)}`
      };

      await updateDoc(ticketRef, {
        workTimes: arrayUnion(workTimeEntry),
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      // Frissítjük a lokális state-et
      setTicket(prev => ({
        ...prev,
        workTimes: [...(prev.workTimes || []), workTimeEntry],
        history: [...(prev.history || []), historyEntry]
      }));

      // Form törlése
      setWorkTime({
        description: '',
        hours: '',
        minutes: '',
        isSubmitting: false
      });
    } catch (err) {
      console.error('Munkaidő hozzáadási hiba:', err);
      setError('Hiba történt a munkaidő hozzáadásakor: ' + err.message);
    } finally {
      setWorkTime(prev => ({ ...prev, isSubmitting: false }));
    }
  };

  const handleEditWorkTime = async (e, index) => {
    e.preventDefault();
    if (!editingWorkTime) return;

    setWorkTime(prev => ({ ...prev, isSubmitting: true }));
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);

      // Átváltás percekre
      const totalMinutes = (parseInt(editingWorkTime.hours || 0) * 60) + parseInt(editingWorkTime.minutes || 0);
      
      if (totalMinutes === 0) {
        throw new Error('Kérjük adjon meg munkaidőt');
      }

      // Új munkaidő bejegyzés
      const updatedWorkTimeEntry = {
        description: editingWorkTime.description.trim(),
        minutes: totalMinutes,
        createdAt: ticket.workTimes[index].createdAt, // Eredeti létrehozási idő megtartása
        updatedAt: now.toISOString(), // Módosítás ideje
        createdBy: ticket.workTimes[index].createdBy, // Eredeti létrehozó megtartása
        updatedBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        }
      };

      // History bejegyzés
      const historyEntry = {
        action: 'worktime_edited',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: `Munkaidő módosítva: ${formatWorkTime(totalMinutes)}`
      };

      // Munkaidő lista frissítése
      const updatedWorkTimes = [...ticket.workTimes];
      updatedWorkTimes[index] = updatedWorkTimeEntry;

      await updateDoc(ticketRef, {
        workTimes: updatedWorkTimes,
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      // Lokális state frissítése
      setTicket(prev => ({
        ...prev,
        workTimes: updatedWorkTimes,
        history: [...(prev.history || []), historyEntry]
      }));

      setEditingWorkTime(null); // Szerkesztés befejezése
    } catch (err) {
      console.error('Munkaidő módosítási hiba:', err);
      setError('Hiba történt a munkaidő módosításakor: ' + err.message);
    } finally {
      setWorkTime(prev => ({ ...prev, isSubmitting: false }));
    }
  };

  const startEditing = (workTimeEntry, index) => {
    const hours = Math.floor(workTimeEntry.minutes / 60);
    const minutes = workTimeEntry.minutes % 60;
    setEditingWorkTime({
      index,
      description: workTimeEntry.description,
      hours: hours.toString(),
      minutes: minutes.toString()
    });
  };

  const handleDeleteWorkTime = async (index) => {
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);
      
      // Az eredeti munkaidő bejegyzés
      const workTimeToDelete = ticket.workTimes[index];

      // History bejegyzés
      const historyEntry = {
        action: 'worktime_deleted',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: `Munkaidő törölve: ${formatWorkTime(workTimeToDelete.minutes)} - ${workTimeToDelete.description}`
      };

      // Munkaidő lista frissítése a törölt elem nélkül
      const updatedWorkTimes = ticket.workTimes.filter((_, i) => i !== index);

      await updateDoc(ticketRef, {
        workTimes: updatedWorkTimes,
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      // Lokális state frissítése
      setTicket(prev => ({
        ...prev,
        workTimes: updatedWorkTimes,
        history: [...(prev.history || []), historyEntry]
      }));

      setDeletingWorkTime(null); // Törlés modal bezárása
    } catch (err) {
      console.error('Munkaidő törlési hiba:', err);
      setError('Hiba történt a munkaidő törlésekor: ' + err.message);
    }
  };

  const handleEditComment = async (e, index) => {
    e.preventDefault();
    if (!editingComment?.content.trim()) return;

    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);

      // Új komment adatok
      const updatedCommentEntry = {
        content: editingComment.content.trim(),
        createdAt: ticket.comments[index].createdAt, // Eredeti létrehozási idő
        createdBy: ticket.comments[index].createdBy, // Eredeti létrehozó
        updatedAt: now.toISOString(),
        updatedBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        }
      };

      // History bejegyzés
      const historyEntry = {
        action: 'comment_edited',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: 'Hozzászólás módosítva'
      };

      // Komment lista frissítése
      const updatedComments = [...ticket.comments];
      updatedComments[index] = updatedCommentEntry;

      await updateDoc(ticketRef, {
        comments: updatedComments,
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      // Lokális state frissítése
      setTicket(prev => ({
        ...prev,
        comments: updatedComments,
        history: [...(prev.history || []), historyEntry]
      }));

      setEditingComment(null);
    } catch (err) {
      console.error('Hozzászólás módosítási hiba:', err);
      setError('Hiba történt a hozzászólás módosításakor: ' + err.message);
    }
  };

  const handleDeleteComment = async (index) => {
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);
      
      const commentToDelete = ticket.comments[index];

      // History bejegyzés
      const historyEntry = {
        action: 'comment_deleted',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: 'Hozzászólás törölve'
      };

      // Komment lista frissítése a törölt elem nélkül
      const updatedComments = ticket.comments.filter((_, i) => i !== index);

      await updateDoc(ticketRef, {
        comments: updatedComments,
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      // Lokális state frissítése
      setTicket(prev => ({
        ...prev,
        comments: updatedComments,
        history: [...(prev.history || []), historyEntry]
      }));

      setDeletingComment(null);
    } catch (err) {
      console.error('Hozzászólás törlési hiba:', err);
      setError('Hiba történt a hozzászólás törlésekor: ' + err.message);
    }
  };

  // Összes munkaidő számítása
  const totalWorkTime = ticket?.workTimes?.reduce((total, wt) => total + wt.minutes, 0) || 0;

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  if (loading) return <LoadingScreen />;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!ticket) return <div>Ticket nem található</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="md:flex md:items-center md:justify-between mb-6">
        <div className="flex-1 min-w-0">
          <div className="flex items-center">
            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800 mr-3">
              #{ticket.ticketNumber || '---'}
            </span>
            {!isEditing ? (
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                {ticket.title}
              </h2>
            ) : null}
          </div>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4 space-x-2">
          <button
            onClick={() => navigate(`/organization/${orgId}/tickets`)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Vissza
          </button>
          {!isEditing ? (
            <button
              onClick={() => setIsEditing(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={updating}
            >
              Szerkesztés
            </button>
          ) : null}
          
          {/* Státusz módosító dropdown */}
          <div className="relative inline-block text-left" ref={menuRef}>
            <div>
              <button
                type="button"
                className={`inline-flex justify-between items-center w-50 px-4 py-2 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 
                  ${getStatusButtonStyle(ticket.status)}`}
                id="status-menu"
                aria-expanded={isMenuOpen}
                aria-haspopup="true"
                disabled={updating}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <span className="font-medium">Státusz</span>
                <div className="flex items-center space-x-2">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(ticket.status)}`}>
                    {getStatusText(ticket.status)}
                  </span>
                  <svg 
                    className={`h-5 w-5 transition-transform duration-200 ${isMenuOpen ? 'transform rotate-180' : ''}`} 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 20 20" 
                    fill="currentColor"
                  >
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              </button>
            </div>

            {/* Státusz választó dropdown menü */}
            {isMenuOpen && (
              <div className="origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100">
                <div className="py-1">
                  {['new', 'in_progress', 'resolved', 'closed'].map((status) => (
                    <button
                      key={status}
                      onClick={() => handleStatusChange(status)}
                      disabled={ticket.status === status || updating}
                      className={`w-full text-left px-4 py-2 text-sm ${
                        ticket.status === status 
                          ? 'bg-gray-100 text-gray-900 font-medium'
                          : 'text-gray-700 hover:bg-gray-50'
                      } flex justify-between items-center`}
                    >
                      <span>{getStatusText(status)}</span>
                      {ticket.status === status && (
                        <svg className="h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                      )}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Szerkesztő űrlap */}
      {isEditing ? (
        <form onSubmit={handleEdit} className="space-y-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div>
            <label htmlFor="title" className="block text-sm font-medium text-gray-700">
              Tárgy
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="title"
                id="title"
                value={editForm.title}
                onChange={(e) => setEditForm(prev => ({ ...prev, title: e.target.value }))}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                required
              />
            </div>
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Leírás
            </label>
            <div className="mt-1">
              <textarea
                id="description"
                name="description"
                rows={4}
                value={editForm.description}
                onChange={(e) => setEditForm(prev => ({ ...prev, description: e.target.value }))}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div className="mt-4">
            <label htmlFor="deadline" className="block text-sm font-medium text-gray-700">
              Határidő
            </label>
            <input
              type="datetime-local"
              name="deadline"
              id="deadline"
              value={editForm.deadline}
              onChange={handleEditFormChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Mégse
            </button>
            <button
              type="submit"
              disabled={updating}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {updating ? 'Mentés...' : 'Mentés'}
            </button>
          </div>
        </form>
      ) : (
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="prose max-w-none">
            <p>{ticket.description}</p>
          </div>
        </div>
      )}

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {ticket.title}
          </h3>
          <div className="text-sm text-gray-500">
            Létrehozva: {formatDate(ticket.createdAt)}
          </div>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Státusz</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {ticket.status}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Prioritás</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {ticket.priority}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Létrehozta</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {ticket.createdBy?.email}
              </dd>
            </div>
            {ticket.updatedAt && (
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Utoljára módosítva</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {formatDate(ticket.updatedAt)}
                  {ticket.updatedBy?.email && ` - ${ticket.updatedBy.email}`}
                </dd>
              </div>
            )}
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Leírás</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {ticket.description}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Határidő</dt>
              <dd className={`mt-1 text-sm sm:mt-0 sm:col-span-2 ${
                ticket.deadline && new Date(ticket.deadline) < new Date() ? 'text-red-600 font-medium' : 'text-gray-900'
              }`}>
                {ticket.deadline ? formatDate(ticket.deadline) : 'Nincs határidő'}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {/* Két oszlopos elrendezés */}
      <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Munkaidő nyilvántartás szekció */}
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Munkaidő nyilvántartás
              </h3>
              <span className="text-sm font-medium text-gray-500">
                Összes munkaidő: {formatWorkTime(totalWorkTime)}
              </span>
            </div>
            
            {/* Munkaidő lista */}
            <div className="space-y-4 mb-6 max-h-[600px] overflow-y-auto">
              {ticket.workTimes && ticket.workTimes.length > 0 ? (
                ticket.workTimes.map((workTimeEntry, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-4">
                    {editingWorkTime?.index === index ? (
                      // Szerkesztő form
                      <form onSubmit={(e) => handleEditWorkTime(e, index)} className="space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Leírás
                          </label>
                          <textarea
                            rows={2}
                            className="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                            value={editingWorkTime.description}
                            onChange={(e) => setEditingWorkTime(prev => ({ ...prev, description: e.target.value }))}
                          />
                        </div>
                        
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Óra
                            </label>
                            <input
                              type="number"
                              min="0"
                              max="999"
                              className="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                              value={editingWorkTime.hours}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 999)) {
                                  setEditingWorkTime(prev => ({ ...prev, hours: value }));
                                }
                              }}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Perc
                            </label>
                            <input
                              type="number"
                              min="0"
                              max="59"
                              className="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                              value={editingWorkTime.minutes}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 59)) {
                                  setEditingWorkTime(prev => ({ ...prev, minutes: value }));
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="flex justify-end space-x-2">
                          <button
                            type="button"
                            onClick={() => setEditingWorkTime(null)}
                            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Mégse
                          </button>
                          <button
                            type="submit"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Mentés
                          </button>
                        </div>
                      </form>
                    ) : (
                      // Megjelenítés
                      <>
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center space-x-2">
                            <span className="font-medium text-gray-900">
                              {workTimeEntry.createdBy.displayName || workTimeEntry.createdBy.name || workTimeEntry.createdBy.email}
                            </span>
                            <span className="text-sm text-gray-500">
                              {formatDate(workTimeEntry.createdAt)}
                            </span>
                            {workTimeEntry.updatedAt && (
                              <span className="text-sm text-gray-500 italic">
                                (módosítva: {formatDate(workTimeEntry.updatedAt)})
                              </span>
                            )}
                          </div>
                          <div className="flex items-center space-x-2">
                            <span className="text-sm font-medium text-gray-700">
                              {formatWorkTime(workTimeEntry.minutes)}
                            </span>
                            <button
                              onClick={() => startEditing(workTimeEntry, index)}
                              className="text-indigo-600 hover:text-indigo-900"
                              title="Szerkesztés"
                            >
                              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                              </svg>
                            </button>
                            <button
                              onClick={() => setDeletingWorkTime(index)}
                              className="text-red-600 hover:text-red-900"
                              title="Törlés"
                            >
                              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <p className="text-gray-700 whitespace-pre-wrap">
                          {workTimeEntry.description}
                        </p>
                      </>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-gray-500 text-center py-4">
                  Még nincs rögzített munkaidő
                </p>
              )}
            </div>

            {/* Új munkaidő form */}
            <form onSubmit={handleAddWorkTime} className="mt-6 space-y-4">
              <div>
                <label htmlFor="workTimeDescription" className="block text-sm font-medium text-gray-700">
                  Leírás
                </label>
                <textarea
                  id="workTimeDescription"
                  name="description"
                  rows={2}
                  className="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Végzett munka leírása..."
                  value={workTime.description}
                  onChange={(e) => setWorkTime(prev => ({ ...prev, description: e.target.value }))}
                  disabled={workTime.isSubmitting}
                />
              </div>
              
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="workTimeHours" className="block text-sm font-medium text-gray-700">
                    Óra
                  </label>
                  <input
                    type="number"
                    id="workTimeHours"
                    name="hours"
                    min="0"
                    max="999"
                    className="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                    placeholder="0"
                    value={workTime.hours}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 999)) {
                        setWorkTime(prev => ({ ...prev, hours: value }));
                      }
                    }}
                    disabled={workTime.isSubmitting}
                  />
                </div>
                <div>
                  <label htmlFor="workTimeMinutes" className="block text-sm font-medium text-gray-700">
                    Perc
                  </label>
                  <input
                    type="number"
                    id="workTimeMinutes"
                    name="minutes"
                    min="0"
                    max="59"
                    className="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                    placeholder="0"
                    value={workTime.minutes}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 59)) {
                        setWorkTime(prev => ({ ...prev, minutes: value }));
                      }
                    }}
                    disabled={workTime.isSubmitting}
                  />
                </div>
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={
                    workTime.isSubmitting || 
                    !workTime.description.trim() || 
                    (workTime.hours === '' && workTime.minutes === '') ||
                    (parseInt(workTime.hours || 0) === 0 && parseInt(workTime.minutes || 0) === 0)
                  }
                  className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white 
                    ${workTime.isSubmitting || !workTime.description.trim() || (workTime.hours === '' && workTime.minutes === '')
                      ? 'bg-gray-300 cursor-not-allowed' 
                      : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    }`}
                >
                  {workTime.isSubmitting ? 'Mentés...' : 'Munkaidő rögzítése'}
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Hozzászólások szekció */}
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Hozzászólások
            </h3>
            
            {/* Komment lista */}
            <div className="space-y-4 mb-6 max-h-[600px] overflow-y-auto">
              {ticket.comments && ticket.comments.length > 0 ? (
                ticket.comments.map((comment, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-4">
                    {editingComment?.index === index ? (
                      // Szerkesztő form
                      <form onSubmit={(e) => handleEditComment(e, index)} className="space-y-4">
                        <textarea
                          rows={3}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={editingComment.content}
                          onChange={(e) => setEditingComment(prev => ({ ...prev, content: e.target.value }))}
                        />
                        <div className="flex justify-end space-x-2">
                          <button
                            type="button"
                            onClick={() => setEditingComment(null)}
                            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Mégse
                          </button>
                          <button
                            type="submit"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Mentés
                          </button>
                        </div>
                      </form>
                    ) : (
                      <>
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center space-x-2">
                            <span className="font-medium text-gray-900">
                              {comment.createdBy.displayName || comment.createdBy.name || comment.createdBy.email}
                            </span>
                            <span className="text-sm text-gray-500">
                              {formatDate(comment.createdAt)}
                            </span>
                            {comment.updatedAt && (
                              <span className="text-sm text-gray-500 italic">
                                (módosítva: {formatDate(comment.updatedAt)})
                              </span>
                            )}
                          </div>
                          {/* Csak a saját kommentjeinket szerkeszthetjük/törölhetjük */}
                          {comment.createdBy.id === currentUser.uid && (
                            <div className="flex items-center space-x-2">
                              <button
                                onClick={() => setEditingComment({ index, content: comment.content })}
                                className="text-indigo-600 hover:text-indigo-900"
                                title="Szerkesztés"
                              >
                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                              </button>
                              <button
                                onClick={() => setDeletingComment(index)}
                                className="text-red-600 hover:text-red-900"
                                title="Törlés"
                              >
                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                              </button>
                            </div>
                          )}
                        </div>
                        <p className="text-gray-700 whitespace-pre-wrap">
                          {comment.content}
                        </p>
                      </>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-gray-500 text-center py-4">
                  Még nincsenek hozzászólások
                </p>
              )}
            </div>

            {/* Új komment form */}
            <form onSubmit={handleAddComment} className="mt-6">
              <div>
                <label htmlFor="comment" className="sr-only">
                  Új hozzászólás
                </label>
                <textarea
                  id="comment"
                  name="comment"
                  rows={3}
                  className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Írja be hozzászólását..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  disabled={isSubmittingComment}
                />
              </div>
              <div className="mt-3 flex justify-end">
                <button
                  type="submit"
                  disabled={isSubmittingComment || !comment.trim()}
                  className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white 
                    ${isSubmittingComment || !comment.trim() 
                      ? 'bg-gray-300 cursor-not-allowed' 
                      : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    }`}
                >
                  {isSubmittingComment ? 'Küldés...' : 'Hozzászólás küldése'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Törlés megerősítő modal */}
      {deletingWorkTime !== null && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Munkaidő törlése
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Biztosan törölni szeretné ezt a munkaidő bejegyzést? Ezt a műveletet nem lehet visszavonni.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => handleDeleteWorkTime(deletingWorkTime)}
                >
                  Törlés
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setDeletingWorkTime(null)}
                >
                  Mégse
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Törlés megerősítő modal kommentekhez */}
      {deletingComment !== null && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Hozzászólás törlése
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Biztosan törölni szeretné ezt a hozzászólást? Ezt a műveletet nem lehet visszavonni.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => handleDeleteComment(deletingComment)}
                >
                  Törlés
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setDeletingComment(null)}
                >
                  Mégse
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function getStatusButtonStyle(status) {
  switch (status) {
    case 'new':
      return 'bg-blue-100 text-blue-800 border-blue-300 hover:bg-blue-200';
    case 'in_progress':
      return 'bg-yellow-100 text-yellow-800 border-yellow-300 hover:bg-yellow-200';
    case 'resolved':
      return 'bg-green-100 text-green-800 border-green-300 hover:bg-green-200';
    case 'closed':
      return 'bg-gray-100 text-gray-800 border-gray-300 hover:bg-gray-200';
    default:
      return 'bg-gray-100 text-gray-800 border-gray-300 hover:bg-gray-200';
  }
}

function getStatusText(status) {
  switch (status) {
    case 'new': return 'Új';
    case 'in_progress': return 'Folyamatban';
    case 'resolved': return 'Megoldva';
    case 'closed': return 'Lezárva';
    default: return status;
  }
}

// Státusz badge színek
function getStatusBadgeColor(status) {
  switch (status) {
    case 'new':
      return 'bg-blue-100 text-blue-800';
    case 'in_progress':
      return 'bg-yellow-100 text-yellow-800';
    case 'resolved':
      return 'bg-green-100 text-green-800';
    case 'closed':
      return 'bg-gray-100 text-gray-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
} 