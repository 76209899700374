import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, orderBy, doc, getDoc } from 'firebase/firestore';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
import LoadingSpinner from '../ui/LoadingSpinner';

function getPriorityStyle(priority) {
  switch (priority) {
    case 'urgent':
      return 'bg-red-50 hover:bg-red-100 border-l-4 border-red-500';
    case 'high':
      return 'bg-orange-50 hover:bg-orange-100 border-l-4 border-orange-500';
    case 'normal':
      return 'bg-white hover:bg-gray-50 border-l-4 border-blue-500';
    case 'low':
      return 'bg-gray-50 hover:bg-gray-100 border-l-4 border-gray-500';
    default:
      return 'bg-white hover:bg-gray-50';
  }
}

function formatDate(timestamp) {
  if (!timestamp) return 'Nincs dátum';
  
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  
  try {
    const date = timestamp.seconds ? 
      new Date(timestamp.seconds * 1000) : 
      new Date(timestamp);
      
    return date.toLocaleDateString('hu-HU', options);
  } catch (error) {
    console.error('Dátum formázási hiba:', error);
    return 'Érvénytelen dátum';
  }
}

export default function TicketList() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Új state-ek a szűréshez
  const [filters, setFilters] = useState({
    status: '',
    priority: '',
    searchTerm: '',
    clientId: '',
    dateFrom: '',
    dateTo: '',
    month: '' // YYYY-MM formátumban
  });

  // Szűrők kezelése
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Szűrt ticketek
  const filteredTickets = tickets.filter(ticket => {
    const matchesStatus = !filters.status || ticket.status === filters.status;
    const matchesPriority = !filters.priority || ticket.priority === filters.priority;
    const matchesClient = !filters.clientId || ticket.clientId === filters.clientId;
    const matchesSearch = !filters.searchTerm || 
      ticket.title.toLowerCase().includes(filters.searchTerm.toLowerCase());
    
    // Dátum szűrés
    let matchesDate = true;
    const ticketDate = new Date(ticket.createdAt);

    if (filters.month) {
      const [year, month] = filters.month.split('-');
      matchesDate = ticketDate.getFullYear() === parseInt(year) && 
                   ticketDate.getMonth() === parseInt(month) - 1;
    } else {
      if (filters.dateFrom) {
        matchesDate = matchesDate && ticketDate >= new Date(filters.dateFrom);
      }
      if (filters.dateTo) {
        matchesDate = matchesDate && ticketDate <= new Date(filters.dateTo);
      }
    }
    
    return matchesStatus && matchesPriority && matchesClient && matchesSearch && matchesDate;
  });

  // Először betöltjük a szervezet adatait
  useEffect(() => {
    async function fetchOrganization() {
      try {
        console.log('Fetching organization:', orgId); // Debug log
        const orgDoc = await getDoc(doc(db, 'organizations', orgId));
        if (orgDoc.exists()) {
          const orgData = orgDoc.data();
          console.log('Organization data:', orgData); // Debug log
          setOrganization(orgData);
        } else {
          console.log('Organization not found'); // Debug log
          setError('Szervezet nem található');
        }
      } catch (err) {
        console.error('Error fetching organization:', err); // Debug log
        setError('Hiba történt a szervezet betöltésekor');
      }
    }

    if (orgId) {
      fetchOrganization();
    }
  }, [orgId]);

  // Majd betöltjük a ticketeket
  useEffect(() => {
    async function fetchTickets() {
      try {
        console.log('Fetching tickets for org:', orgId); // Debug log
        const q = query(
          collection(db, 'tickets'),
          where('organizationId', '==', orgId),
          orderBy('createdAt', 'desc')
        );
        const querySnapshot = await getDocs(q);
        const ticketList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log('Tickets loaded:', ticketList); // Debug log
        setTickets(ticketList);
      } catch (err) {
        console.error('Error fetching tickets:', err); // Debug log
        setError('Hiba történt a ticketek betöltésekor');
      } finally {
        setLoading(false);
      }
    }

    if (orgId) {
      fetchTickets();
    }
  }, [orgId]);

  // A filteredTickets konstans után
  const calculateClientStats = (clientId) => {
    if (!clientId) return { totalTime: 0, totalCost: 0 };
    
    // Csak azokat a ticketeket nézzük, amik megfelelnek a szűrési feltételeknek
    const clientTickets = filteredTickets.filter(ticket => ticket.clientId === clientId);
    
    return clientTickets.reduce((acc, ticket) => {
      const workTimes = ticket.workTimes || [];
      
      workTimes.forEach(wt => {
        const workDate = new Date(wt.createdAt);
        
        // Dátum szűrés a munkaidő bejegyzésekre
        let includeWorkTime = true;
        
        if (filters.month) {
          const [year, month] = filters.month.split('-');
          includeWorkTime = workDate.getFullYear() === parseInt(year) && 
                           workDate.getMonth() === parseInt(month) - 1;
        } else {
          if (filters.dateFrom) {
            includeWorkTime = includeWorkTime && workDate >= new Date(filters.dateFrom);
          }
          if (filters.dateTo) {
            includeWorkTime = includeWorkTime && workDate <= new Date(filters.dateTo);
          }
        }

        if (includeWorkTime) {
          acc.totalTime += wt.minutes;
          const client = organization?.clients?.find(c => c.id === clientId);
          if (client?.hourlyRate) {
            acc.totalCost += (wt.minutes / 60) * client.hourlyRate;
          }
        }
      });
      
      return acc;
    }, { totalTime: 0, totalCost: 0 });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <LoadingSpinner size="large" color="indigo" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-red-500">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Fejléc a szervezet nevével */}
      <div className="mb-6">
        <h1 className="text-3xl font-bold text-gray-900">
          {organization?.name || 'Szervezet'} - Ticketek
        </h1>
        <nav className="mt-2">
          <ol className="flex items-center space-x-2 text-sm text-gray-500">
            <li>
              <Link to="/" className="hover:text-gray-700">Kezdőlap</Link>
            </li>
            <li>
              <span className="mx-2">/</span>
            </li>
            <li>
              <Link to={`/organization/${orgId}`} className="hover:text-gray-700">
                {organization?.name || 'Szervezet'}
              </Link>
            </li>
            <li>
              <span className="mx-2">/</span>
            </li>
            <li className="text-gray-900">Ticketek</li>
          </ol>
        </nav>
      </div>

      {/* Műveletek */}
      <div className="mb-6">
        <button
          onClick={() => navigate(`/organization/${orgId}/tickets/new`)}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Új ticket
        </button>
      </div>

      {/* Szűrők */}
      <div className="bg-white p-4 rounded-lg shadow mb-6">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-4 lg:grid-cols-6">
          {/* Státusz szűr */}
          <div>
            <label htmlFor="status" className="block text-sm font-medium text-gray-700">
              Státusz
            </label>
            <select
              id="status"
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="">Összes státusz</option>
              <option value="new">Új</option>
              <option value="in_progress">Folyamatban</option>
              <option value="resolved">Megoldva</option>
              <option value="closed">Lezárva</option>
            </select>
          </div>

          {/* Prioritás szűrő */}
          <div>
            <label htmlFor="priority" className="block text-sm font-medium text-gray-700">
              Prioritás
            </label>
            <select
              id="priority"
              name="priority"
              value={filters.priority}
              onChange={handleFilterChange}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option value="">Összes prioritás</option>
              <option value="low">Alacsony</option>
              <option value="normal">Normál</option>
              <option value="high">Magas</option>
              <option value="urgent">Sürgős</option>
            </select>
          </div>

          {/* Keresés tárgy szerint */}
          <div>
            <label htmlFor="searchTerm" className="block text-sm font-medium text-gray-700">
              Keresés tárgy szerint
            </label>
            <input
              type="text"
              name="searchTerm"
              id="searchTerm"
              value={filters.searchTerm}
              onChange={handleFilterChange}
              placeholder="Keresés..."
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            />
          </div>

          {/* Ügyfél szűrő a meglévő szűrők mellé */}
          <div>
            <label htmlFor="clientFilter" className="block text-sm font-medium text-gray-700">
              Ügyfél
            </label>
            <select
              id="clientFilter"
              name="clientId"
              value={filters.clientId}
              onChange={handleFilterChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value="">Összes ügyfél</option>
              {organization?.clients?.map(client => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </select>
          </div>

          {/* A meglévő szűrők után, a grid-ben */}
          <div>
            <label htmlFor="month" className="block text-sm font-medium text-gray-700">
              Hónap
            </label>
            <input
              type="month"
              id="month"
              name="month"
              value={filters.month}
              onChange={handleFilterChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="dateFrom" className="block text-sm font-medium text-gray-700">
              Dátumtól
            </label>
            <input
              type="date"
              id="dateFrom"
              name="dateFrom"
              value={filters.dateFrom}
              onChange={handleFilterChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div>
            <label htmlFor="dateTo" className="block text-sm font-medium text-gray-700">
              Dátumig
            </label>
            <input
              type="date"
              id="dateTo"
              name="dateTo"
              value={filters.dateTo}
              onChange={handleFilterChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        {/* Találatok száma és szűrők törlése */}
        <div className="mt-4 flex justify-between items-center">
          <div className="text-sm text-gray-500">
            {filteredTickets.length} ticket található
          </div>
          {(filters.status || filters.priority || filters.searchTerm || filters.clientId) && (
            <button
              onClick={() => setFilters({ status: '', priority: '', searchTerm: '', clientId: '' })}
              className="text-sm text-indigo-600 hover:text-indigo-900"
            >
              Szűrők törlése
            </button>
          )}
        </div>
      </div>

      {filters.clientId && (
        <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              {organization?.clients?.find(c => c.id === filters.clientId)?.name || 'Ügyfél'} - Havi ráfordított idő
            </h3>
            <div className="text-2xl font-bold text-indigo-600">
              {(() => {
                const { totalTime } = calculateClientStats(filters.clientId);
                const hours = Math.floor(totalTime / 60);
                const minutes = totalTime % 60;
                return `${hours} óra ${minutes} perc`;
              })()}
            </div>
            <p className="text-sm text-gray-500 mt-1">
              {filters.month ? (
                new Date(filters.month).toLocaleDateString('hu-HU', { year: 'numeric', month: 'long' })
              ) : filters.dateFrom || filters.dateTo ? (
                <>
                  {filters.dateFrom && new Date(filters.dateFrom).toLocaleDateString('hu-HU')}
                  {' - '}
                  {filters.dateTo && new Date(filters.dateTo).toLocaleDateString('hu-HU')}
                </>
              ) : (
                'Teljes időszak'
              )}
            </p>
          </div>
          
          <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              Havi költség
            </h3>
            <div className="text-2xl font-bold text-indigo-600">
              {(() => {
                const { totalCost } = calculateClientStats(filters.clientId);
                const client = organization?.clients?.find(c => c.id === filters.clientId);
                return new Intl.NumberFormat('hu-HU', {
                  style: 'currency',
                  currency: client?.currency || 'HUF'
                }).format(Math.round(totalCost));
              })()}
            </div>
            <p className="text-sm text-gray-500 mt-1">
              {filters.month ? (
                new Date(filters.month).toLocaleDateString('hu-HU', { year: 'numeric', month: 'long' })
              ) : filters.dateFrom || filters.dateTo ? (
                <>
                  {filters.dateFrom && new Date(filters.dateFrom).toLocaleDateString('hu-HU')}
                  {' - '}
                  {filters.dateTo && new Date(filters.dateTo).toLocaleDateString('hu-HU')}
                </>
              ) : (
                'Teljes időszak'
              )}
            </p>
          </div>
        </div>
      )}

      {/* Ticketek listája */}
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        {filteredTickets.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {filteredTickets.map((ticket) => (
              <li key={ticket.id} className={getPriorityStyle(ticket.priority)}>
                <Link
                  to={`/organization/${orgId}/tickets/${ticket.id}`}
                  className="block transition-colors duration-150 ease-in-out"
                >
                  <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                          #{ticket.ticketNumber || '---'}
                        </span>
                        <p className="text-sm font-medium text-gray-900 truncate">
                          {ticket.title}
                        </p>
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusBadgeColor(ticket.status)}`}>
                          {getStatusText(ticket.status)}
                        </span>
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getPriorityBadgeColor(ticket.priority)}`}>
                          {getPriorityText(ticket.priority)}
                        </span>
                      </div>
                      <div className="flex items-center space-x-4 text-sm text-gray-500">
                        {ticket.createdBy?.displayName ? (
                          <span className="hidden sm:inline-block">
                            {ticket.createdBy.displayName}
                          </span>
                        ) : ticket.createdBy?.name ? (
                          <span className="hidden sm:inline-block">
                            {ticket.createdBy.name}
                          </span>
                        ) : ticket.createdBy?.email ? (
                          <span className="hidden sm:inline-block">
                            {ticket.createdBy.email}
                          </span>
                        ) : null}
                        <div className="flex items-center space-x-4">
                          <p className="text-sm text-gray-500">
                            Létrehozva: {formatDate(ticket.createdAt)}
                          </p>
                          {ticket.deadline && (
                            <p className={`text-sm ${
                              new Date(ticket.deadline) < new Date() ? 'text-red-600 font-medium' : 'text-gray-500'
                            }`}>
                              Határidő: {formatDate(ticket.deadline)}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    {ticket.description && (
                      <p className="mt-2 text-sm text-gray-500 truncate">
                        {ticket.description}
                      </p>
                    )}
                    <div className="flex items-center space-x-2 mt-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd" />
                      </svg>
                      <span className={`text-sm ${
                        ticket.deadline && new Date(ticket.deadline) < new Date() ? 'text-red-600 font-medium' : 'text-gray-500'
                      }`}>
                        {ticket.deadline ? formatDate(ticket.deadline) : 'Nincs határidő'}
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center py-6 text-gray-500">
            Nincs a szűrésnek megfelelő ticket
          </div>
        )}
      </div>
    </div>
  );
}

// Segédfüggvények a státusz és prioritás megjelenítéséhez
function getStatusBadgeColor(status) {
  switch (status) {
    case 'new': return 'bg-blue-100 text-blue-800';
    case 'in_progress': return 'bg-yellow-100 text-yellow-800';
    case 'resolved': return 'bg-green-100 text-green-800';
    case 'closed': return 'bg-gray-100 text-gray-800';
    default: return 'bg-gray-100 text-gray-800';
  }
}

function getStatusText(status) {
  switch (status) {
    case 'new': return 'Új';
    case 'in_progress': return 'Folyamatban';
    case 'resolved': return 'Megoldva';
    case 'closed': return 'Lezárva';
    default: return status;
  }
}

function getPriorityText(priority) {
  switch (priority) {
    case 'low': return 'Alacsony';
    case 'normal': return 'Normál';
    case 'high': return 'Magas';
    case 'urgent': return 'Sürgős';
    default: return priority;
  }
}

// Prioritás badge színek
function getPriorityBadgeColor(priority) {
  switch (priority) {
    case 'urgent':
      return 'bg-red-100 text-red-800';
    case 'high':
      return 'bg-orange-100 text-orange-800';
    case 'normal':
      return 'bg-blue-100 text-blue-800';
    case 'low':
      return 'bg-gray-100 text-gray-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
} 