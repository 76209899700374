import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';

// Magyar karakterek támogatása
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf'
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    padding: 30,
    fontSize: 10
  },
  header: {
    marginBottom: 20
  },
  title: {
    fontSize: 18,
    marginBottom: 5
  },
  subtitle: {
    fontSize: 14,
    color: '#666',
    marginBottom: 15
  },
  section: {
    marginBottom: 15
  },
  sectionTitle: {
    fontSize: 14,
    marginBottom: 10,
    backgroundColor: '#f3f4f6',
    padding: 5
  },
  table: {
    display: 'table',
    width: 'auto',
    marginBottom: 10,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e5e7eb'
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#e5e7eb',
    minHeight: 25,
    alignItems: 'center'
  },
  tableHeader: {
    backgroundColor: '#f3f4f6',
    fontWeight: 'bold'
  },
  tableCell: {
    padding: 5,
    fontSize: 9
  },
  summaryBox: {
    backgroundColor: '#f3f4f6',
    padding: 10,
    marginBottom: 15,
    borderRadius: 4
  }
});

function formatWorkTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours} óra ${mins} perc`;
}

export default function MonthlyReportPDF({ reportData, month, organization }) {
  // Early return if data is not ready
  if (!reportData?.clientDetails || !month || !organization) {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Text style={styles.title}>Adatok betöltése...</Text>
          </View>
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Fejléc */}
        <View style={styles.header}>
          <Text style={styles.title}>
            {organization.name} - Havi munkaidő kimutatás
          </Text>
          <Text style={styles.subtitle}>
            {format(month, 'yyyy. MMMM', { locale: hu })}
          </Text>
        </View>

        {/* Összesítő */}
        <View style={styles.summaryBox}>
          <Text>Összes munkaidő: {formatWorkTime(reportData.summary.totalWorkTime || 0)}</Text>
          <Text>Összes ticket: {reportData.summary.totalTickets || 0} db</Text>
          <Text>Érintett ügyfelek: {reportData.summary.totalClients || 0} db</Text>
          <Text>Átlagos munkaidő/ticket: {formatWorkTime(reportData.summary.averageTimePerTicket || 0)}</Text>
        </View>

        {/* Ügyfelenkénti részletes kimutatás */}
        {Object.entries(reportData.clientDetails || {}).map(([clientId, client]) => (
          <View key={clientId} style={styles.section} break>
            <Text style={styles.sectionTitle}>{client.name}</Text>
            
            {/* Ügyfél összesítő */}
            <View style={styles.summaryBox}>
              <Text>Összes munkaidő: {formatWorkTime(client.totalWorkTime)}</Text>
              <Text>Ticketek száma: {client.tickets.length} db</Text>
            </View>

            {/* Tételes munkaidő lista */}
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <Text style={[styles.tableCell, { flex: 2 }]}>Dátum</Text>
                <Text style={[styles.tableCell, { flex: 3 }]}>Ticket</Text>
                <Text style={[styles.tableCell, { flex: 4 }]}>Leírás</Text>
                <Text style={[styles.tableCell, { flex: 1 }]}>Idő</Text>
              </View>

              {client.workTimeEntries
                .sort((a, b) => b.date - a.date)
                .map((entry, index) => (
                  <View key={index} style={styles.tableRow}>
                    <Text style={[styles.tableCell, { flex: 2 }]}>
                      {format(entry.date.toDate(), 'yyyy.MM.dd', { locale: hu })}
                    </Text>
                    <Text style={[styles.tableCell, { flex: 3 }]}>
                      {entry.ticketTitle}
                    </Text>
                    <Text style={[styles.tableCell, { flex: 4 }]}>
                      {entry.description}
                    </Text>
                    <Text style={[styles.tableCell, { flex: 1 }]}>
                      {formatWorkTime(entry.time)}
                    </Text>
                  </View>
                ))}
            </View>

            {/* Ticket státuszok */}
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <Text style={[styles.tableCell, { flex: 4 }]}>Ticket</Text>
                <Text style={[styles.tableCell, { flex: 1 }]}>Státusz</Text>
                <Text style={[styles.tableCell, { flex: 1 }]}>Prioritás</Text>
                <Text style={[styles.tableCell, { flex: 2 }]}>Határidő</Text>
              </View>

              {client.tickets.map((ticket, index) => (
                <View key={index} style={styles.tableRow}>
                  <Text style={[styles.tableCell, { flex: 4 }]}>{ticket.title}</Text>
                  <Text style={[styles.tableCell, { flex: 1 }]}>{ticket.status}</Text>
                  <Text style={[styles.tableCell, { flex: 1 }]}>{ticket.priority}</Text>
                  <Text style={[styles.tableCell, { flex: 2 }]}>
                    {ticket.deadline ? format(ticket.deadline.toDate(), 'yyyy.MM.dd', { locale: hu }) : '-'}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        ))}
      </Page>
    </Document>
  );
} 